function Polyfills() {

    if('objectFit' in document.documentElement.style !== false) {
        import('object-fit-images' /* webpackChunkName: "object-fit-images" */ /* webpackPrefetch: true */).then(
            module => {
                module.default();
            }
        );
    }

    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                    Element.prototype.webkitMatchesSelector;
      }
      
      if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
          var el = this;
      
          do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
          } while (el !== null && el.nodeType === 1);
          return null;
        };
      }
    
}



export default Polyfills;