import fontawesome from '@fortawesome/fontawesome';
//import light from '@fortawesome/fontawesome-pro-light';
//import solid from '@fortawesome/fontawesome-pro-solid';
//import regular from '@fortawesome/fontawesome-pro-regular';
//import brands from '@fortawesome/fontawesome-free-brands';


import faSearch from '@fortawesome/fontawesome-pro-solid/faSearch';
import faChevron from '@fortawesome/fontawesome-pro-solid/faChevronRight';
import faTimes from '@fortawesome/fontawesome-pro-solid/faTimes';
import faLink from '@fortawesome/fontawesome-pro-solid/faLink';
import faFile from '@fortawesome/fontawesome-pro-regular/faFile';
import faFilePdf from '@fortawesome/fontawesome-pro-regular/faFilePdf';
import faFileImage from '@fortawesome/fontawesome-pro-regular/faFileImage';
import faLongArrow from '@fortawesome/fontawesome-pro-regular/faLongArrowAltRight';
import faUser from '@fortawesome/fontawesome-pro-solid/faUser';
import faCog from '@fortawesome/fontawesome-pro-solid/faCog';
import faGraduationCap from '@fortawesome/fontawesome-pro-solid/faGraduationCap';
import faArrowRight from '@fortawesome/fontawesome-pro-solid/faArrowRight';
import faHome from '@fortawesome/fontawesome-pro-solid/faHome';
import faThumbsUp from '@fortawesome/fontawesome-pro-regular/faThumbsUp';
import faClipboardList from '@fortawesome/fontawesome-pro-regular/faClipboardList';
import faCompass from '@fortawesome/fontawesome-pro-regular/faCompass';
import faBarChart from '@fortawesome/fontawesome-pro-regular/faChartBar';
import faGlobe from '@fortawesome/fontawesome-pro-regular/faGlobe';
import faPaperPlane from '@fortawesome/fontawesome-pro-regular/faPaperPlane';
import faBrowser from '@fortawesome/fontawesome-pro-regular/faBrowser';
import faCamera from '@fortawesome/fontawesome-pro-regular/faCameraRetro';
import faQuestionCircle from '@fortawesome/fontawesome-pro-regular/faQuestionCircle';
import faCalendar from '@fortawesome/fontawesome-pro-regular/faCalendarAlt';
import faEnvelope from '@fortawesome/fontawesome-pro-regular/faEnvelope';
import faPhone from '@fortawesome/fontawesome-pro-solid/faPhone';
import faSchool from '@fortawesome/fontawesome-pro-regular/faSchool';
import faNews from '@fortawesome/fontawesome-pro-regular/faNewspaper';

const FontAwesome = function() {
  fontawesome.library.add(
    faSearch,
    faChevron,
    faTimes,
    faLink,
    faFile,
    faFilePdf,
    faFileImage,
    faLongArrow,
    faUser,
    faCog,
    faGraduationCap,
    faArrowRight,
    faHome,
    faThumbsUp,
    faClipboardList,
    faCompass,
    faBarChart,
    faGlobe,
    faPaperPlane,
    faBrowser,
    faCamera,
    faQuestionCircle,
    faCalendar,
    faEnvelope,
    faPhone,
    faSchool,
    faNews
  );
};

export default FontAwesome;
