/* eslint-disable no-unused-vars */

import "@babel/polyfill";
import FontAwesome from './theme/fontawesome';
import polyfills from './theme/utils/polyfills.js';
import 'array-from-polyfill';
import 'promise-polyfill/src/polyfill';
import 'element-closest';


window.addEventListener('load', () => {
  polyfills();
  FontAwesome();

  // Query the DOM for dynamic imports

  const sliderElements = document.querySelectorAll('.slider-block .slider');
  const faqs = Array.from(document.querySelectorAll('.faq-wrapper'));
  const openHouse = Array.from(document.querySelectorAll('.open-house-wrapper'));
  const programGuide = Array.from(document.querySelectorAll('.program-guide-wrapper'));
  const accordionElements = document.querySelectorAll('.handorgel');


  if (sliderElements) {
    import('./theme/slider' /* webpackChunkName: "slider" */ /* webpackPrefetch: true */).then(
      module => {
        module.default(sliderElements);
      }
    );
  }

  if (faqs.length > 0) {
    import('./theme/faq-filter' /* webpackChunkName: "faq-filter" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

  if (openHouse.length > 0) {
    import('./theme/open-house-filter' /* webpackChunkName: "open-house-filter" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

  if (programGuide.length > 0) {
    import('./theme/program-guide-filter' /* webpackChunkName: "program-guide-filter" */ /* webpackPrefetch: true */).then(
      module => {
        module.default();
      }
    );
  }

  if (accordionElements) {

    import('./theme/accordion' /* webpackChunkName: "accordion" */ /* webpackPrefetch: true */).then(
      module => {
        module.default(accordionElements);
      }
    );
  }

  import('./theme/navbar' /* webpackChunkName: "navbar" */ /* webpackPrefetch: true */).then(
    module => {
      module.default();
    }
  );
});
